.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url('./images/splash.png'), linear-gradient(75deg, #FFFF02, #1AFEC1);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  font-family: 'Karla';
}

.app footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.app footer a {
  font-size: 14px;
  padding: 10px;
  color: #000;
}

.account-linked {
  display: flex;
  flex: 1;
  max-height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 20px;
  background: white;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
